import { Snackbar } from '@material-ui/core';
import { Alert } from '@mui/material';
import React, { createContext, useContext } from 'react';

const SnackBarContext = createContext();

export default function SnackBarProvider({
  children,
}){
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [typeColor, setTypeColor] = React.useState('info');

  const showSnackBar = (text, color) => {
    setMessage(text);
    setTypeColor(color);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTypeColor('info');
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={typeColor}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
};

export const useSnackBar = () => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error('useSnackBar must be used within an SnackBarProvider');
  }

  return context;
};

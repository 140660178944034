import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

function Empty() {
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%'
        }}>
         <Outlet />
      </Box>
    </>
  );
}

export default Empty;
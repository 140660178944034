import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../../assets/components/snooze.css';
import { useGlobal } from '../../../context/global';
import useSession from '../../../context/session';
import useTimer from '../../../helpers/hooks/useTimer';

function Snooze(props) {
  const { snoozeView, activeSnooze, snoozeOption, blockEvent, validateBlockList } = useGlobal();
  const { time, startTimer, updateTime } = useTimer(120); //120 seconds => 2 minutes
  const { session } = useSession();
  const [ viewSnooze , setViewSnooze ] = useState(false);

  const minutes = Math.floor(time / 60);
  const preliminarSeconds = (time - minutes * 60);
  const seconds = preliminarSeconds < 10 ? ['0',preliminarSeconds].join('') : preliminarSeconds;

  const handleSnooze = () => {
    // TODO: pretty sure this doesn't work so i need to investigate
    updateTime(300);
    activeSnooze();
    startTimer();
  };

  useEffect(() => {
    if(snoozeView && blockEvent && session.snooze){
      startTimer();
      setViewSnooze(true);
    }
    if(seconds === '00' && minutes === 0){
      validateBlockList(null, null);
      setViewSnooze(false);
    }
  });

  return (
    <>
      { viewSnooze ? (
        <div className="snooze_container">
          <div className="snooze_layout">
            <div className="snooze_countdown">
              <p>Ending In {`${minutes}:${seconds}`}</p>
            </div>
            <div className="snooze_button">
              <Button variant="contained" disabled={snoozeOption} onClick={handleSnooze}>
                Snooze
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Snooze;

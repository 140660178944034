import { Box, Popover, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import Radioform from './radioform.component';
import Blocklist from './blocklist.component';
import useSession from '../../../context/session';
import ClientApi from '../../../services/api.service';
import { useGlobal } from '../../../context/global';
import { getDateTime } from '../../../helpers/utils.core';
import { useSnackBar } from '../../../context/snackbar';

function Sidebar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { session } = useSession();
  const { addBlockList, validateBlockList, isFullAutoModalOpen, manageFullAutoModal } = useGlobal();
  const { showSnackBar } = useSnackBar();
  const api = new ClientApi();

  useEffect(() => {
    fetchBlockData();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    manageFullAutoModal();
  };
  const handleClose = () => {
    setAnchorEl(null);
    manageFullAutoModal();
  };
  const fetchBlockData = async () => {
    const requestBlockEvents = await api.readEventsToday(session.nursing_home);
    if (requestBlockEvents.status === 200) {
      const blockevents = requestBlockEvents.data;
      blockevents.sort((a, b) => (getDateTime(a.start_time) > getDateTime(b.start_time) ? 1 : getDateTime(b.start_time) > getDateTime(a.start_time) ? -1 : 0));
      addBlockList(blockevents);
      validateBlockList(blockevents, null);
    }else{
      showSnackBar(`Error request: ${requestBlockEvents.status}`, 'error');
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
          <>
            <Stack direction="row" spacing={1}>
	      <div onClick={handleClick}>
	        <Box sx={{}}>
	          <img
	            src={session.logo}
	            onError={({ currentTarget }) => {
	              currentTarget.onerror = null;
	              currentTarget.src = 'https://d27zrhjaaq909m.cloudfront.net/default_program_guide_photo.png';
	            }}
	            loading="lazy"
	            alt="logo"
	            style={{
	              height: 'auto',
	            }}
	          />
	        </Box>
	      </div>
	      {session.full_auto ? (
                <Popover
                  open={isFullAutoModalOpen}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Radioform />
                </Popover>
	      ) : (
                <></>
              )}
            </Stack>
          </>

        <Box sx={{ flexGrow: 1, height: '75vh', overflow: 'auto' }}>
          <Blocklist />
        </Box>
      </Box>
    </>
  );
}

export default Sidebar;

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { useGlobal } from '../../../context/global';

function Radioform(props) {

  const { fullAutoMode, switchAutoModeOn, switchAutoModeOff, validateBlockList, manageFullAutoModal } = useGlobal();

  const [value, setValue] = React.useState(fullAutoMode ? 'ON' : 'OFF');
  const handleChange = async(event) => {
    if(event.target.value === 'ON'){
      switchAutoModeOn();
      validateBlockList(null, null);
    }else{
      switchAutoModeOff()
    }
    setValue(event.target.value);
    manageFullAutoModal();
  };

  return (
    <>
      <FormControl variant="standard">
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          sx={{
            p: 1.5,
            backgroundColor: '#EFF4FB'
          }}
        >
          <FormControlLabel value={'ON'} control={<Radio />} label="Full auto mode" />
          <FormControlLabel value={'OFF'} control={<Radio />} label="Manual mode" />
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default Radioform;

import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useSession from '../../context/session';
import { useGlobal } from '../../context/global';


function Session(props) {

  const navigate = useNavigate();
  const { getSession, saveJWT, clear, decodeToken } = useSession();
  const { switchAutoModeOn } = useGlobal();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('t');
    if(token){
      const tokenData = decodeToken(token);
      if(getSession()){
        clear();
      }
      if(tokenData.full_auto){
        switchAutoModeOn();
      }
      saveJWT(token);
      navigate('/main');
    }else{
      navigate('/unauthorized');
    }
  });

  return (
    <Container component="div" maxWidth="l">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Box component="div" sx={{ mt: 1 }}>
          <CircularProgress color='inherit' />
        </Box>
        <Box component="div" sx={{ mt: 1 }}>
          <Typography variant="h4" component="p" gutterBottom>
            Validating session....
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Session;

import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from "react";
import jwtparse from "../../helpers/utils.jwt";

const SessionContext = createContext();
const sessionKey = 'tvguide-session';
const keepOnWindowClosed = false;

export function SessionProvider({ children }) {

  const getStorage = () => {
    return keepOnWindowClosed ? localStorage : sessionStorage;
  }

  const getSession = () => {
    try {
      const storageValue = getStorage().getItem(sessionKey);
      if (storageValue != null) {
        try {
          const session = JSON.parse(storageValue);
          return session;
        } catch {
          return storageValue;
        }
      }
    } catch {
      console.warn("useSession could not access the browser storage. Session will be lost when closing browser window")
    }
    return null;
  };

  const [session, setSession] = useState(getSession);

  const save = (sessionValue) => {
    if (typeof sessionValue == "object" || typeof sessionValue === "string") {
      getStorage().setItem(sessionKey, typeof sessionValue == "object" ? JSON.stringify(sessionValue) : sessionValue);
      setSession(sessionValue);
    } else {
      throw new Error("useSession hook only accepts objects or strings as session values");
    }
  }

  const saveJWT = (jwt) => {
    let parsedObject;
    try {
      parsedObject = jwtparse(jwt);
      parsedObject.token = jwt;
    } catch (ex) {
      throw new Error("Could not parse provided Json Web Token: " + ex);
    }

    save(parsedObject);
  }

  const clear = () => {
    getStorage().removeItem(sessionKey);
    setSession(null);
  }

  const syncState = (event) => {
    if (event.key === sessionKey) {
      setSession(getSession());
    }
  }

  const decodeToken = (jwt) => {
    return jwtparse(jwt);
  }

  const values = {
    getSession,
    save,
    session,
    saveJWT,
    clear,
    syncState,
    decodeToken
  }

  useEffect(() => {
    window.addEventListener("storage", syncState);
    return () => {
      window.removeEventListener("storage", syncState);
    };
  }, [sessionKey]);


  return (
    <SessionContext.Provider value={values}>
      {children}
    </SessionContext.Provider>
  );
}

export default function useSession() {
  return useContext(SessionContext);
}

import React, { useEffect, useRef } from 'react';
import { useGlobal } from '../../context/global';
import { refresh_delay, snooze_delay } from '../../core/constants.core';
import Layout from './components/layout.component';
import Snooze from './components/snooze.component';

function Home(props) {
  const { switchAutoModeOn, fullAutoMode, snoozeOption, validateBlockList, inactiveSnooze, inactiveSnoozeView } = useGlobal();
  const timerRef = useRef(null);
  useEffect(() => {
    if (snoozeOption) {
      timerRef.current = setTimeout(() => {
        validateBlockList(null, null);
        inactiveSnooze();
        inactiveSnoozeView();
      }, snooze_delay);
    }
    return () => clearTimeout(timerRef.current);
  }, [snoozeOption]);
  let timeout = null;
  const switchOption = () => {
    switchAutoModeOn();
    validateBlockList(null, null);
  };
  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (!fullAutoMode) {
        switchOption();
      }
    }, refresh_delay);
  };
  document.addEventListener('mousemove', restartAutoReset);

  return (
    <>
      <Snooze />
      <Layout />
    </>
  );
}

export default Home;

import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles.css";
import { SessionProvider } from "./context/session";
import { BrowserRouter } from "react-router-dom";
import PlayerProvider from "./context/player";
import GlobalProvider from "./context/global";
import SnackBarProvider from "./context/snackbar";

ReactDOM.render(
  <React.StrictMode>
    <SessionProvider>
      <PlayerProvider>
          <GlobalProvider>
            <SnackBarProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </SnackBarProvider>
          </GlobalProvider>
      </PlayerProvider>
    </SessionProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

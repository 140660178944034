export const defaultStaticLocation = "https://d2s68xyhetsqtm.cloudfront.net/telly/";
export const defaultFillerVideo = defaultStaticLocation + "default_filler.mp4";

export const globalReducer = {
  addBlockList: 'ADD_BLOCKLIST',
  addBlockEvent: 'ADD_BLOCKEVENT',
  addEventList: 'ADD_EVENTLIST',
  addEventActivity: 'ADD_EVENT_ACTIVITY',
  addPositionBlock: 'ADD_POSITIONBLOCK',
  addPositionEvent: 'ADD_POSITIONEVENT',
  activeSnooze: 'ACTIVE_SNOOZE',
  inactiveSnooze: 'INACTIVE_SNOOZE',
  activeSnoozeView: 'ACTIVE_SNOOZEVIEW',
  inactiveSnoozeView: 'INACTIVE_SNOOZEVIEW',
  switchAutoModeOn: 'SWITCH_AUTO_MODE_ON',
  switchAutoModeOff: 'SWITCH_AUTO_MODE_OFF',
  resetState: 'RESET_STATE',
  isExternalEventModalOpen: 'IS_EXTERNAL_EVENT_MODAL_OPEN',
  isFullAutoModalOpen: 'IS_FULL_AUTO_MODAL_OPEN',
};
export const externalEventDialog = {
  upcomingLiveEvent: 'A live event is coming up! Would you like to attend?',
  externalSiteEvent: 'You are about to leave the Telly view. Would you like to proceed?',
  yes: 'YES',
  no: 'NO'
}
export const playerReducer = {
  modifySettings: 'MODIFY_SETTINGS',
  activeLoop: 'ACTIVE_LOOP'
};
export const videoTypeEnum= {
  healthMessage: 'Health_Message',
  filler: 'Filler',
  event: 'Event'
}
export const default_delay = 10_000;
export const snooze_delay = 300_000;
export const refresh_delay = 10_800_000;

import { globalReducer } from '../../core/constants.core';

const reducer = (globalState, action) => {
  switch (action.type) {
    case globalReducer.addBlockList:
      return {
        ...globalState,
        blockList: action.payload,
      };
    case globalReducer.addBlockEvent:
      return {
        ...globalState,
        blockEvent: action.payload,
      };
    case globalReducer.addEventActivity:
      return {
        ...globalState,
        eventActivity: action.payload,
      };
    case globalReducer.addEventList:
      return {
        ...globalState,
        eventList: action.payload,
      };
    case globalReducer.addPositionBlock:
      return {
        ...globalState,
        positionBlock: action.payload,
      };
    case globalReducer.addPositionEvent:
      return {
        ...globalState,
        positionEvent: action.payload,
      };
    case globalReducer.switchAutoModeOn:
      return {
        ...globalState,
        fullAutoMode: action.payload,
      };
    case globalReducer.switchAutoModeOff:
      return {
        ...globalState,
        fullAutoMode: action.payload,
      };
    case globalReducer.resetState:
      return action.payload;
    case globalReducer.activeSnooze:
      return {
        ...globalState,
        snoozeOption: true,
      };
    case globalReducer.inactiveSnooze:
      return {
        ...globalState,
        snoozeOption: false,
      };
    case globalReducer.activeSnoozeView:
      return {
        ...globalState,
        snoozeView: true,
      };
    case globalReducer.inactiveSnoozeView:
      return {
        ...globalState,
        snoozeView: false,
      };
    case globalReducer.isExternalEventModalOpen:
      return {
        ...globalState,
        isExternalEventModalOpen: action.payload,
      };
    case globalReducer.isFullAutoModalOpen:
      return {
        ...globalState,
        isFullAutoModalOpen: action.payload,
      };
    default:
      return {};
  }
};
export default reducer;

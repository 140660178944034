import { createContext, useReducer, useContext } from "react";
import { defaultFillerVideo, globalReducer, videoTypeEnum } from "../../core/constants.core";
import { convert12to24, getHourFromTime, getMinutesFromTime, getTimeString, isLiveEvent } from "../../helpers/utils.core";
import { usePlayer } from "../player";
import useSession from "../session";
import GlobalReducer from "./reducer";

const GlobalContext = createContext();

export default function GlobalProvider(props) {

  const { session, getSession } = useSession();
  const { modifySettings } = usePlayer();

  const initialState = {
    blockList: [],
    positionBlock: null,
    blockEvent: null,
    eventActivity: null,
    fullAutoMode: getSession() ? session.full_auto : false,
    eventList: [],
    positionEvent: null,
    snoozeOption: false,
    snoozeView: false,
    isExternalEventModalOpen: false,
    isFullAutoModalOpen: false,
  };

  const [globalState, dispatch] = useReducer(GlobalReducer, initialState);

  const addBlockList = (blockList) => {
    try {
      dispatch({
        type: globalReducer.addBlockList,
        payload: blockList,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const addBlockEvent = (blockEvent) => {
    try {
      dispatch({
        type: globalReducer.addBlockEvent,
        payload: blockEvent,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const addEventList = (eventList) => {
    try {
      dispatch({
        type: globalReducer.addEventList,
        payload: eventList,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const addEventActivity = (event) =>{
    try {
      dispatch({
        type: globalReducer.addEventActivity,
        payload: event,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const addPositionBlock = (position) => {
    try {
      dispatch({
        type: globalReducer.addPositionBlock,
        payload: position,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const addPositionEvent = (position) => {
    try {
      dispatch({
        type: globalReducer.addPositionEvent,
        payload: position,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const switchAutoModeOn = () => {
    try {
      dispatch({
        type: globalReducer.switchAutoModeOn,
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const switchAutoModeOff = () => {
    try {
      dispatch({
        type: globalReducer.switchAutoModeOff,
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const resetState = () => {
    try {
      dispatch({
        type: globalReducer.resetState,
        payload: {
          blockList: globalState.blockList,
          positionBlock: null,
          blockEvent: null,
          eventActivity: null,
          fullAutoMode: session ? session.full_auto : false,
          eventList: [],
          positionEvent: null
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  const activeSnooze = () => {
    try {
      dispatch({
        type: globalReducer.activeSnooze,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const inactiveSnooze = () => {
    try {
      dispatch({
        type: globalReducer.inactiveSnooze,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const activeSnoozeView = () => {
    try {
      dispatch({
        type: globalReducer.activeSnoozeView,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const inactiveSnoozeView = () => {
    try {
      dispatch({
        type: globalReducer.inactiveSnoozeView,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const manageLiveEventsModal = () => {
    try {
      dispatch({
        type: globalReducer.isExternalEventModalOpen,
        payload: !globalState.isExternalEventModalOpen
      });
    } catch (error) {
      console.log(error);
    }
  }

  const manageFullAutoModal = () => {
    try {
      dispatch({
        type: globalReducer.isFullAutoModalOpen,
        payload: !globalState.isFullAutoModalOpen
      });
    } catch (error) {
      console.log(error);
    }
  }
  
  const validateBlockList = (blockList, time) => {
      const currentTime = time ? time : getTimeString();
      const blockListItems = blockList ? blockList : globalState.blockList;

      // grab the first event (in an activity block or stand alone) and set that to the current event
      const currentEvent = blockListItems.find(({start_time, end_time}) =>
        convert12to24(currentTime) >= convert12to24(start_time) &&
        convert12to24(currentTime) <= convert12to24(end_time)
      );

      // if an activity block or event exists at the current time
      if(currentEvent){
        if(session.full_auto) {
          // make sure the activity block or event is in view and centered in the list
          document.getElementById(currentEvent.activity_block ? 'activity_block_' + currentEvent.id : 'event_' + currentEvent.id).scrollIntoView({ block: 'center' });
        }

        if(!globalState.blockEvent || (globalState.blockEvent && currentEvent.id !== globalState.blockEvent.id)){
          addBlockEvent(currentEvent);
          inactiveSnoozeView();

          // auto play a stand alone static event
          if(!currentEvent.activity_block && !isLiveEvent(currentEvent.related_link)){
            modifySettings({
              videoURL: currentEvent.related_link,
              loop: true,
              playing: true,
              videoType: videoTypeEnum.event
            });
          }
        }
      }else{
        if(globalState.blockEvent){
          resetState();
        }
        inactiveSnoozeView();
	//only set the filler video in auto mode
	if(session.full_auto){
          modifySettings({
            videoURL: session.filler_video ? session.filler_video : defaultFillerVideo,
            loop: true,
            playing: true,
            videoType: videoTypeEnum.filler
          });
        }
      }
  }

  const validateTime = (time) => {
    const diffTime = getMinutesFromTime(globalState.blockEvent.end_time) - getMinutesFromTime(time);
    if(diffTime === 2){
      // only show the snooze popup if it is not already shown
      // and if the live event popup is not being displayed
      if(!globalState.snoozeOption && !globalState.isExternalEventModalOpen){
        activeSnoozeView();
      }
    }
    if(diffTime === 5){
      const currentPosition = positionBlockList(globalState.blockEvent);
      const nextBlock = globalState.blockList[currentPosition + 1];
      if(nextBlock){
        const diffHour = getHourFromTime(convert12to24(nextBlock.start_time)) - getHourFromTime(convert12to24(time));
        if(diffHour <= 1 && !nextBlock.activity_block && isLiveEvent(nextBlock.related_link)){
          manageLiveEventsModal();
        }
      }
    }
  }

  const positionBlockList = (blockEvent) => {
    return globalState.blockList.indexOf(blockEvent);
  }

  return (
    <GlobalContext.Provider
      value={{
        blockList: globalState.blockList,
        positionBlock: globalState.positionBlock,
        positionEvent: globalState.positionEvent,
        blockEvent: globalState.blockEvent,
        eventActivity: globalState.eventActivity,
        fullAutoMode: globalState.fullAutoMode,
        eventList: globalState.eventList,
        snoozeOption: globalState.snoozeOption,
        snoozeView: globalState.snoozeView,
        isExternalEventModalOpen: globalState.isExternalEventModalOpen,
        isFullAutoModalOpen: globalState.isFullAutoModalOpen,
        addBlockList,
        addBlockEvent,
        addEventActivity,
        addPositionBlock,
        addPositionEvent,
        addEventList,
        switchAutoModeOn,
        switchAutoModeOff,
        validateBlockList,
        resetState,
        validateTime,
        activeSnooze,
        inactiveSnooze,
        activeSnoozeView,
        inactiveSnoozeView,
        manageLiveEventsModal,
        manageFullAutoModal,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}

export function useGlobal() {
  return useContext(GlobalContext);
}

import React from 'react';
import '../../../assets/components/card.css';
import { Box } from '@mui/material';
import { useGlobal } from '../../../context/global';
import { isLiveEvent, isExternalEvent } from '../../../helpers/utils.core';
import useSession from "../../../context/session";
import ClientApi from "../../../services/api.service";

function Blocklist(props) {
  const { session } = useSession();
  const api = new ClientApi();
  const { blockList, blockEvent, fullAutoMode, addBlockEvent, manageLiveEventsModal } = useGlobal();
  const handleChooseCard = async (event) => {
    // add the event regardless of type
    addBlockEvent(event);

    if (!event.activity_block && isLiveEvent(event.related_link)) {
      const healthMessage = await api.getHealthMessage(session.nursing_home)
      if (healthMessage?.related_link) return
    }

    // open live events modal prompting user to join if it contains a live event link
    if(!fullAutoMode && !event.activity_block && (isLiveEvent(event.related_link) || isExternalEvent(event.related_link))) {
      manageLiveEventsModal();
    }
  };
  const eventTime = (time) => {
    let times = time.split(" - ")
    let startTime = times[0].replace(/^0+/, '')
    let endTime = times[1].replace(/^0+/, '')
    return startTime + " - " + endTime
  }

  return (
    <>
      {
        blockList ? blockList.map((event) => {
          return <div
          // the className assignment looks convoluted and awful but it actually needs to be written this way
          className={`card ${ fullAutoMode ? ( blockEvent ? (blockEvent.id === event.id ? 'card--active' : 'card--disabled') : 'card--disabled') : ( blockEvent ? (blockEvent.id === event.id ? 'card--active' : '') : '')}`}
          key={event.id}
          id={event.activity_block ? 'activity_block_' + event.id : 'event_' + event.id}
          onClick={() => {
            handleChooseCard(event);
          }}
          >
            <div className="card__content">
              <picture className="card__picture">
                <img src={event.image} loading="lazy" alt={event.name} onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src="https://dummyimage.com/600x400/000/fff.png&text=No+image+to+display";
                }} />
              </picture>
              <div className="card__info">
                <small className="card__time">{eventTime(event.time)}</small>
                <h3 className="card__title">{event.type_event === 'external' && !event.related_link.toLowerCase().includes('youtube') && !event.related_link.toLowerCase().includes('content.connectcarehero') ? 'LIVE: ' : ''}{event.name}</h3>
              </div>
            </div>
            <svg width="10" height="17" viewBox="0 0 10 17" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.976074 0.621094L0.272949 1.28906C0.132324 1.46484 0.132324 1.74609 0.272949 1.88672L6.63623 8.25L0.272949 14.6484C0.132324 14.7891 0.132324 15.0703 0.272949 15.2461L0.976074 15.9141C1.15186 16.0898 1.39795 16.0898 1.57373 15.9141L8.95654 8.56641C9.09717 8.39062 9.09717 8.14453 8.95654 7.96875L1.57373 0.621094C1.39795 0.445312 1.15186 0.445312 0.976074 0.621094Z" />
            </svg>
          </div>;
        }) : (
          <>
            <Box component={'div'} sx={{
              p: 2
            }}>
              <p>No events found</p>
            </Box>
          </>
        )
      }
    </>
  );
}

export default Blocklist;

import { Box } from '@mui/material';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { usePlayer } from '../../../context/player';
import '../../../assets/components/player.component.css';
import { useGlobal } from '../../../context/global';
import { videoTypeEnum } from '../../../core/constants.core';
import { resetEventCarousel } from '../../../helpers/utils.core';
import PromoGif from '../../../assets/Telly-Filler-graphic.gif';

function Player(props) {
  const [showPromoGif, setShowPromoGif] = useState(false);
  const { settings, modifySettings } = usePlayer();
  const { positionEvent, eventList, addEventActivity, addPositionEvent, fullAutoMode, manageLiveEventsModal } = useGlobal();
  const [ looping , setLooping] = useState(settings.loop);
  const [ isMute, setIsMute ] = useState(true);

  const handleLoop = () => {
    const lengthEvents = eventList.length;
    if (
      lengthEvents <= 1 &&
      settings.videoType !== videoTypeEnum.healthMessage
    ) {
      setLooping(true);
    }else{
      setLooping(false)
    }
  }

  const handleShowPromoGif = () => {
    if (document.fullscreenElement) {
      document.getElementById("promo-gif").showModal();
    }
    setShowPromoGif(true);
  }

  const handleHidePromoGif = () => {
    if (document.fullscreenElement) {
      document.getElementById("promo-gif").close();
    }
    setShowPromoGif(false);
  }

  const handleShowNextEvent = () => {
    if (videoTypeEnum.event === settings.videoType) {
      const lengthEvents = eventList.length;
      const futurePosition = positionEvent + 1;
      let locationEvent = futurePosition >= lengthEvents ? 0 : futurePosition ;
      let eventActivity = eventList[locationEvent];
      addPositionEvent(locationEvent);
      addEventActivity(eventActivity);
      resetEventCarousel(eventList);

      if(eventActivity && eventActivity.related_link) {
        modifySettings({
          videoURL: eventActivity.related_link,
          loop: false,
          playing: true,
          videoType: videoTypeEnum.event
        })
      }
    }
    if (videoTypeEnum.healthMessage === settings.videoType) {
      manageLiveEventsModal();
      modifySettings({
        videoURL: settings.videoURL,
        loop: false,
        playing: false,
        videoType: videoTypeEnum.event,
      });
    }
  }

  const handleEnded = () => {
    handleShowPromoGif()

    setTimeout(handleHidePromoGif, 3500);

    setTimeout(handleShowNextEvent, 3000);
  };

  const handleReady = () => {
    setIsMute(false);
  }

  const handleError = (e) => {
    // this will unfortunately only work if one link is broken. the hook
    // does not fire if there are two broken links in a row
    if(fullAutoMode) {
        handleShowNextEvent();
    }
    console.log(e);
  }

  const getPlayerProps = (controls) => {
    return {
      url: settings.videoURL,
      playing: settings.playing,
      loop: looping,
      volume: 1,
      muted: isMute,
      onEnded: handleEnded,
      onStart: handleLoop,
      controls: controls,
      onReady: handleReady,
      onError: handleError,
      width: "100%",
      height: "70%",
      config: {
        youtube: {
          playerVars: {
            widget_referrer: window.location.origin,
          },
        },
      },
    };
  };

  return (
    <Box>
      <div className="player">
        <dialog className="dialog-gif" id="promo-gif">
          <img className="promoGif" src={PromoGif} alt="Promo Gif" />
        </dialog>

        {showPromoGif && (
          <div>
            <img className="promoGif" src={PromoGif} alt="Promo Gif" />
          </div>
        )}

        {/* This implementation enables control hiding. */}
        {/* ReactPlayer may not fully react to 'controls' prop changes. */}
        {settings.videoType !== videoTypeEnum.healthMessage && (
          <ReactPlayer {...getPlayerProps(true)} />
        )}
        {settings.videoType === videoTypeEnum.healthMessage && (
          <ReactPlayer {...getPlayerProps(false)} />
        )}
      </div>
    </Box>
  );
}

export default Player;

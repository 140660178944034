import { isNullOrEmptyOrUndefined } from './helpers/utils.config';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

const config = {
  API: process.env.REACT_APP_API,
  CHIME: process.env.REACT_APP_CHIME,
  SECRET: process.env.REACT_APP_SECRET,
  CLOUDWATCH_RUM_IDENTITY_POOL_ID: process.env.REACT_APP_CLOUDWATCH_RUM_IDENTITY_POOL_ID,
  CLOUDWATCH_RUM_APPLICATION_ID: process.env.REACT_APP_CLOUDWATCH_RUM_APPLICATION_ID,
  CLOUDWATCH_RUM_GUEST_ROLE_ARN: process.env.REACT_APP_CLOUDWATCH_RUM_GUEST_ROLE_ARN,
};

try {
  const rumConfig: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: config.CLOUDWATCH_RUM_GUEST_ROLE_ARN,
    identityPoolId: config.CLOUDWATCH_RUM_IDENTITY_POOL_ID,

    endpoint: "https://dataplane.rum.us-east-2.amazonaws.com",
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: false
  };

  const APPLICATION_ID: string = config.CLOUDWATCH_RUM_APPLICATION_ID;
  const APPLICATION_VERSION: string = '1.0.0';
  const APPLICATION_REGION: string = 'us-east-2';

  const awsRum: AwsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    rumConfig
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const valueIsMissing = Object.entries(config).some((x) => {
  if (process.env.NODE_ENV !== 'dev') {
    return false;
  }
  return isNullOrEmptyOrUndefined(x[1]);
});

if (valueIsMissing) {
  const configValuesMissing = Object.entries(config)
    .filter((el) => isNullOrEmptyOrUndefined(el[1]))
    .map((x) => x[0]);
  throw new Error(`Missing env variable(s) ${[...configValuesMissing].join(' - ')}`);
}

export default config;

import { playerReducer } from "../../core/constants.core";

const reducer = (globalState, action) => {
  const body = action.payload;
  switch (action.type) {
    case playerReducer.modifySettings:
      return {
        ...globalState,
        settings: body
      }
    case playerReducer.activeLoop:
      return {
        ...globalState,
        setting: body
      }
    default:
      return {};
  }
};
export default reducer;

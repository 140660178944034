import { createContext, useReducer, useContext } from "react";
import { playerReducer } from "../../core/constants.core";
import PlayerReducer from "./reducer";

const PlayerContext = createContext();

export default function PlayerProvider(props) {
  const initialState = {
    settings: {
      videoURL: null,
      loop: false,
      playing: false,
      videoType: null
    }
  };

  const [globalState, dispatch] = useReducer(PlayerReducer, initialState);


  const modifySettings = (settings) => {
    try {
      dispatch({
        type: playerReducer.modifySettings,
        payload: settings
      });
    } catch (error) {
      console.log(error);
    }
  }

  const activeLoop = () => {
    try {
      dispatch({
        type: playerReducer.activeLoop,
        payload: {
          videoURL: globalState.videoURL,
          loop: true,
          playing: globalState.playing,
          videoType: globalState.videoType
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <PlayerContext.Provider
      value={{
        settings: globalState.settings,
        modifySettings,
        activeLoop
      }}
    >
      {props.children}
    </PlayerContext.Provider>
  );
}

export function usePlayer() {
  return useContext(PlayerContext);
}

import { Box } from '@mui/material';
import React from 'react';

function Main(props) {
  const { children } = props;
  return (
    <>
      <Box
        component="main"
        sx={{
          minWidth: '100%',
          minHeight: '100%'
        }}>
         {
           children
         }
      </Box>
    </>
  );
}

export default Main;
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

function Unauthorized(props) {
  return (
    <Container component="div" maxWidth="l">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <Box component="div" sx={{ mt: 1 }}>
            <Typography variant="h2" component="h2" gutterBottom>
              OOPS!
            </Typography>
          </Box>
          <Box component="div" sx={{ mt: 1 }}>
            <Typography variant="h4" component="p" gutterBottom>
              You are not allowed to enter here
            </Typography>
          </Box>
        </Box>
      </Container>
  );
}

export default Unauthorized;
import React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import useSession from '../context/session';
import Unauthorized from '../pages/error';
import Home from '../pages/main';
import Session from '../pages/session';
import Empty from './empty.layout';
import Main from './main.layout';

function App(props) {

  return (
    <>
      <Routes>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
        <Route index element={<Navigate to="/main" />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/main" element={<Home />} />
        </Route>
        <Route element={<Empty />}>
          <Route path="/session" element={<Session />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Route>
      </Routes>
    </>
  );
}

const ProtectedRoutes = () => {
  const location = useLocation();
  const { getSession } = useSession();
  return getSession() ? <Main><Outlet /></Main> : <Navigate to="/unauthorized" state={{ from: location }} replace />
}

export default App;
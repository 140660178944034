import React, { useEffect, useState } from 'react';
import { isLiveEvent, isExternalEvent, renderClassName, resetEventCarousel, shuffleEvents } from '../../../helpers/utils.core';
import ClientApi from '../../../services/api.service';
import '../../../assets/components/related_events.css';
import { useGlobal } from '../../../context/global';
import { useSnackBar } from '../../../context/snackbar';
import { usePlayer } from '../../../context/player';
import useSession from "../../../context/session";
import { videoTypeEnum, defaultStaticLocation } from '../../../core/constants.core';

function CarouselEvents(props) {

  const { blockEvent, eventList, fullAutoMode, addEventList, addEventActivity, manageLiveEventsModal } = useGlobal();
  const { settings, modifySettings } = usePlayer();
  const { showSnackBar } = useSnackBar();
  const thumbsUpImg = defaultStaticLocation + "thumbs_up.svg";
  const thumbsUpSelectedImg = defaultStaticLocation + "thumbs_up_selected.svg";
  const thumbsDownImg = defaultStaticLocation + "thumbs_down.svg";
  const thumbsDownSelectedImg = defaultStaticLocation + "thumbs_down_selected.svg";
  const [likeEvent, setLikeEvent] = useState(null);
  const api = new ClientApi();
  const { session } = useSession();

  useEffect(() => {
    if(blockEvent){
      loadEventsbyBlock(blockEvent);
    }
  },[blockEvent]);

  const loadEventsbyBlock = async(blockEvent) =>{
    const today = new Date();

    // if this is a standalone event not a part of an activity block,
    // don't query the api but just add it to the event list
    if (!blockEvent.activity_block){
      if (isLiveEvent(blockEvent.related_link)) {
        const healthMessage = await api.getHealthMessage(session.nursing_home);
        if (healthMessage?.related_link) {
          modifySettings({
            videoURL: healthMessage.related_link,
            loop: false,
            playing: true,
            videoType: videoTypeEnum.healthMessage,
          });
        }
      }

      addEventList([blockEvent]);
    } else {
      const blockId = blockEvent.id
      const requestEvents = await api.readEventsSelect(blockId);
      if (requestEvents.status === 200) {
        let events = requestEvents.data;
        events.sort((a, b) =>
          parseInt(a.id) - parseInt(b.id)
        );

        // the event ids should be in descending order
        events.reverse();

        let index = today.getDay() - 1 >= 0 ? today.getDay() - 1 : 6;
        events = shuffleEvents(events, index);

        // default the ratings for each event to be hidden
        for(let event in events) {
            events[event].showRatings = false;
        }

        addEventList(events);

        if(fullAutoMode){
          addEventActivity(events[0]);
          modifySettings({
            videoURL: events[0].related_link,
            loop: false,
            playing: true,
            videoType: videoTypeEnum.event
          })
        }
      }else{
        showSnackBar(`Error request: ${requestEvents.status}`, 'error');
      }
    }
  }

  const handleEventClick = (selectedEvent) => {
    addEventActivity(selectedEvent);

    setLikeEvent(selectedEvent.like);

    if(selectedEvent.id && settings.videoURL === selectedEvent.related_link && settings.playing) {
      document.getElementById(selectedEvent.id).className = "disabled_event";
      selectedEvent.showRatings = true;
    }

    // only internal events can be added to an activity block. live events are handled in handleChooseCard in blocklist.component.js.
    // pre-recorded content from the content market can also come here which is why event.type_event should not be checked.
    // if a live event is clicked on, it will show up in the carousel. if that is clicked on, pop up the live events notification
    if(!isLiveEvent(selectedEvent.related_link) && !isExternalEvent(selectedEvent.related_link)) {
      // do not check settings.playing because that breaks
      // functionality for locations that default to manual mode
      if(settings.videoURL !== selectedEvent.related_link) {
        modifySettings({
          videoURL: selectedEvent.related_link,
          loop: true,
          playing: true,
          videoType: videoTypeEnum.event
        });

        resetEventCarousel(eventList);
      }
    } else if(settings.videoType !== videoTypeEnum.healthMessage) {
      manageLiveEventsModal();
    }
  }

  const handleLikeDislikeClick = (selectedEvent, likeDislike) => {
    // this is set to handle the state update when clicked
    setLikeEvent(likeDislike);

    // actually set the like property in the eventList which is the local setting
    selectedEvent.like = likeDislike;

    // post status to the api
    api.postEventLike(selectedEvent.id, likeDislike);
  }

  return (
    <div className="events">
      {
        eventList ? (
          <>
            <span className="events__heading">Videos</span>
            <div className="events__carousel">
              {
                eventList.map((event, i) => typeof event.end_time != 'undefined' && (
                  <div className="event__wrapper" key={event.id}>
                    {event.showRatings && (<div className="event__ratings">
                      <img
                          className="event__thumbs_up"
                          onClick={() => {handleLikeDislikeClick(event, true)}}
                          src={likeEvent === true ? thumbsUpSelectedImg : thumbsUpImg}
                          alt="thumbs up"
                      />
                      <img
                          className="event__thumbs_down"
                          onClick={() => {handleLikeDislikeClick(event, false)}}
                          src={likeEvent === false ? thumbsDownSelectedImg : thumbsDownImg}
                          alt="thumbs down"
                      />
                    </div>)}
                    <div
                      key={event.id}
                      id={event.id}
                      className={renderClassName(event.end_time, event.related_link)}
                      onClick={() => {
                        handleEventClick(event)
                      }}>
                      {event.image != null && (<picture className="event__picture">
                        <img src={event.image} loading="lazy" alt={event.name} onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src="https://dummyimage.com/600x400/000/fff.png&text=No+image+to+display";
                        }} />
                      </picture>)}
                      <span className="event__name">{event.name}</span>
                    </div>
                  </div>
		))
              }
            </div>
          </>
        ) : <><p>No program</p></>
      }
    </div>
  );
}

export default CarouselEvents;

import React from 'react';
import '../../../assets/containers/main.css';
import CarouselEvents from './carousel.component';
import Clock from './clock.component';
import Player from './player.component';
import Sidebar from './sidebar.component';
import { DialogContent, Dialog, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { useGlobal } from '../../../context/global';
import { externalEventDialog } from '../../../core/constants.core';
import { isLiveEvent } from '../../../helpers/utils.core';
import { usePlayer } from '../../../context/player';

function Layout(props) {
  const { blockEvent, isExternalEventModalOpen, manageLiveEventsModal } = useGlobal();
  const { modifySettings } = usePlayer();
  const closeModal = () => {
    manageLiveEventsModal()
  };
  const joinLiveOrExternalEvent = (event) => {
    // turn off any video that is playing if the
    // user wants to join the live event
    modifySettings({
      videoURL: null,
      loop: false,
      playing: true,
      videoType: null
    })

    window.open(
      event.related_link,
      "_blank"
    )

    // close the modal
    manageLiveEventsModal()
  }
  return (
    <div className="container_layout">
      <div className="blocklist_container">
        <Sidebar />
      </div>
      <div className="stream_container">
        <div>
          <Player />
        </div>
        <div>
          <CarouselEvents />
        </div>
      </div>
      <Clock />
      <Dialog open={isExternalEventModalOpen} onClose={closeModal} aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {blockEvent && isLiveEvent(blockEvent.related_link) ? externalEventDialog.upcomingLiveEvent : externalEventDialog.externalSiteEvent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {joinLiveOrExternalEvent(blockEvent)}}>{externalEventDialog.yes}</Button>
          <Button onClick={closeModal}>{externalEventDialog.no}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Layout;

import axios from 'axios';
import config from '../config';

const baseURL = `${config.API}`;

class Axios {
  instance;
  constructor() {
    this.instance = axios.create({
      baseURL
    });

    this._initializeResponseInterceptor();
  }

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError,
    );
  };

  _handleResponse = ({ data, status }) => {
    return { data, status}
  };

  _handleError = (error) => {
    console.log(error)
    return error.response ? error.response : Promise.reject(new Error(error));
  }
}

export default Axios;
import Axios from './axios.service';
import config from '../config';

class ClientApi extends Axios {
  token;
  constructor(token) {
    super(config.API || 'http://localhost:8080');
    this.token = token;
    this._initializeRequestInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  _handleRequest = (config) => {
    config.headers = {
      Authorization: this.token ? `Bearer ${this.token}` : "",
    };
    return config;
  };

  readEventsToday = (id) => this.instance.get(`admin/schedule_events/detail_activity_block?today=true&nursing_home=${id}`, {
    // 20 seconds is a crazy timeout but is possible until we upgrade our infrastructure
    timeout: 20000
  });

  readEventsSelect = (id) => this.instance.get(`admin/schedule_events/detail_activity_block?activity_block=${id}`, {
    // same as above. this is honestly too high of a timeout but it is being added to try to alleviate errors
    timeout: 20000
  });

  postEventLike = (eventId, like) => this.instance.post('admin/schedule_events/event_like_dislike', {
    eventId: eventId,
    like: like,
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  getHealthMessage = (nursing_home_id) => {
    return this.instance.get(`api/v1/health-messaging/health-message?nursing_home_id=${nursing_home_id}`).then(response => response.data)
  };
}

export default ClientApi;

import React, { useEffect, useState } from "react";
import { getOrdinalDateString, getTimeString } from "../../../helpers/utils.core";
import useInterval from "../../../helpers/hooks/useInterval";
import "../../../assets/components/clock.component.css";
import { useGlobal } from "../../../context/global";
import { default_delay } from "../../../core/constants.core";

const Clock = () => {
  const date = getOrdinalDateString();
  const { validateBlockList, blockEvent, fullAutoMode, validateTime} = useGlobal();
  const [time, setTime] = useState(getTimeString());

  useInterval(() => {
    setTime(getTimeString());
  }, default_delay);

  useEffect(() => {
    if(time.includes('12:00') && time.includes('AM')) window.location.reload();
    if(fullAutoMode){
      validateBlockList(null, time);
    }
    // TODO: time validation will only work if an activity block
    // is active? what if a live event is the first event of the day?
    if(blockEvent && fullAutoMode){
      validateTime(time);
    }
  },[time]);

  return (
    <div className="clock">
      <small className="clock__date">{date}</small>
      <p className="clock__time">{time}</p>
    </div>
  );
};

export default Clock;
